<div style="padding-left: 10px;padding-right: 10px;">
    @if(!internalView){
    <h2 style="margin-bottom: 20px;" class="sub-heading">Parts &amp; Labor</h2>
    <p class="info-2">Please provide an estimate for parts &amp; labor</p>}
    <div aflex>
        <div *ngIf="tripCharge">
            <app-display-total [smallMode]="true" [hideTriangle]="true" [total]="tripCharge"
                label="Trip Charge"></app-display-total>
        </div>
        <div> <app-display-total [smallMode]="true" [hideTriangle]="true" [total]="approvedTotalWithoutTrip"
                [label]="amtRequestedLabel"></app-display-total>

            <div *ngIf="isDiagnosis && !editHours" style="text-align: right;">
                <span style="
                font-size: 11px;
                font-style: italic;">{{laborHours}} hour(s) &#64; {{laborRate | specialCurrency}}/hour</span>
                <button mat-button style="margin-right: -64px" *ngIf="internalView" (click)="editHours = true">
                    <mat-icon>edit</mat-icon>
                </button>

            </div>
            <div *ngIf="editHours" style="text-align: right">
                <button mat-button style="text-align: center" *ngFor="let hr of hours" class="toggle-button"
                    [class.selected]="hr === laborHours" (click)="laborHours = hr; editHours = false;">
                    {{hr}}
                </button>
            </div>
        </div>
        <div>
            <app-display-total [smallMode]="true" [hideTriangle]="true" [total]="oopFinal"
                [label]="outOfPocketLabel"></app-display-total>
        </div>
    </div>

    <div *ngIf="amountGreaterThanLimit" style="font-style: italic;" class="fade-in-up">
        * Requested amount exceeds the limit of {{perOccurrenceLimit | currency}}.
    </div>
</div>
<div class="padding" *ngIf="!internalView">
    <div class="center" topmargin20>
        <button mat-button mat-raised-button color="primary" [disabled]="!hasAuthorizationLines || authorizing"
            (click)="saveAuthorization()">
            <div>
                <mat-spinner *ngIf="authorizing" class="twenty"></mat-spinner>
                Next <mat-icon>chevron_right</mat-icon>
            </div>
        </button><br>
    </div>
</div>
<div *ngIf="internalView">
    <table class="table">

        <thead>
            <tr>
                <th></th>
                <th>Item</th>
                <th class="right">Price</th>
                <th class="right">Qty</th>
                <th class="right">Total</th>
                <th style="text-align: center">Elevate<br>Provided</th>
                <th style="width: 64px"></th>
            </tr>

        </thead>
        <tbody>
            <ng-container *ngFor="let group of groups">

                <tr [class.strike-through]="isDenial">
                    <td colspan="7" class="info-2">
                        {{group.group}}
                        <!-- @if(!isDenial) {
                    {{group.group}}
                    } @else {
                        DENIED
                    } -->
                    </td>
                </tr>
                <tr *ngFor="let item of group.costLines">
                    <td style="width: 76px">
                        @if(item.isQuestion) {
                        <mat-icon class="covered-icon-sm">help</mat-icon>
                        } @else if(getIsLineCovered(item)) {
                        <mat-icon class="covered-icon-sm" style="color: green;">done</mat-icon>
                        } @else {
                        <mat-icon class="covered-icon-sm" style="color: orange;">do_not_disturb_on</mat-icon>
                        }
                    </td>
                    <td>
                        {{item.description}}
                    </td>
                    <td class="right">
                        {{item.amount | currency}}
                        <div *ngIf="showCostLinePriceIndicator(item)" style="margin-top: 0"
                            class="price-indicator-band">


                            <svg id="Layer_1" class="arrow-2" [style.left]="getCostLinePctDisplay(item)"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.71 20.8">
                                <defs>
                                    <style>
                                        .cls-1 {
                                            fill: #b3b3b3;
                                        }

                                        .cls-2 {
                                            fill: lime;
                                        }
                                    </style>
                                </defs>
                                <path class="cls-2" [style.fill]="getArrowColor(item)"
                                    d="M1.71,20.3c-.46,0-.87-.25-1.08-.66-.21-.41-.17-.89.1-1.26L13.37,1c.23-.32.59-.5.98-.5s.75.18.98.5l12.64,17.38c.27.38.31.85.1,1.26-.21.41-.61.66-1.08.66H1.71Z" />
                                <path class="cls-1"
                                    d="M14.35,1c.23,0,.44.11.58.29l12.64,17.38c.23.32.12.62.06.74s-.24.39-.63.39H1.71c-.39,0-.57-.27-.63-.39s-.17-.42.06-.74L13.78,1.29c.14-.19.34-.29.58-.29M14.35,0c-.52,0-1.04.23-1.38.7L.33,18.08c-.82,1.13-.01,2.72,1.38,2.72h25.28c1.4,0,2.21-1.59,1.38-2.72L15.74.7c-.34-.47-.86-.7-1.38-.7h0Z" />
                            </svg>
                        </div>
                    </td>
                    <td class="right">
                        {{item.qty}}
                    </td>
                    <td class="right">
                        {{item.ext | currency}}
                    </td>
                    <td style="text-align: center">
                        <mat-checkbox [(ngModel)]="item.companyProvidesPart"
                            [disabled]="!item.companyProvidedAvailable"></mat-checkbox>
                    </td>
                    <td>
                        <button mat-button [matMenuTriggerFor]="menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="editLine(item)">
                                <mat-icon>edit</mat-icon> Edit
                            </button>
                            <button mat-menu-item (click)="deleteItem(item, $event)">
                                <mat-icon>delete</mat-icon> Delete
                            </button>

                            <button mat-menu-item *ngIf="(group.group === 'Out Of Pocket' || group.group === 'Unknown')"
                                (click)="approveOOP(item, $event)">
                                <mat-icon>check</mat-icon>
                                Approve
                            </button>

                            <button mat-menu-item *ngIf="group.group === 'Covered'" (click)="markAsOutOfPocket(item)">
                                <mat-icon>do_not_disturb_on</mat-icon> Mark as Out of Pocket
                            </button>
                        </mat-menu>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <div class="center">
        <button mat-fab class="add-btn" (click)="createNew()">
            <mat-icon>add</mat-icon>
        </button>
    </div>
</div>

<ul class="lines" *ngIf="!internalView">

    @for(group of groups; track group) {
    <li class="info-2 group-heading">{{group.group}}</li>
    <li *ngFor="let item of group.costLines" [@simpleFadeAnimation] (click)="editLine(item)">

        <div matRipple>
            @if(item.isQuestion) {
            <mat-icon class="covered-icon-sm">help</mat-icon>
            } @else if(getIsLineCovered(item)) {
            <mat-icon class="covered-icon-sm" style="color: green;">done</mat-icon>
            } @else {
            <mat-icon class="covered-icon-sm" style="color: orange;">do_not_disturb_on</mat-icon>
            }
        </div>
        <div matRipple>
            <h3 style="margin: 0;line-height: 20px;">
                {{item.description}}</h3>

            <div aflex style="margin-top: 10px;">
                <div>
                    <div class="data-label">Unit Price</div>
                    @if(!item.companyProvidesPart){
                    {{item.amount | currency}}} @else {
                    n/a
                    }
                </div>
                <div>
                    <div class="data-label">Qty</div>
                    {{item.qty}}
                </div>
                <div>
                    <div class="data-label">Total</div>
                    @if(!item.companyProvidesPart){
                    {{item.ext | currency}}} @else {
                    n/a
                    }
                </div>
            </div>
            <div *ngIf="item.selectedAttributes">
                <span *ngFor="let attr of item.selectedAttributes" class="badge">
                    {{attr.question}}: {{attr.answer}}
                </span>
            </div>


        </div>
        <div>
            <button mat-button (click)="deleteItem(item, $event)">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </li>
    }

    <li style="text-align: center;" [class.no-item-add]="costLines.length === 0">
        <button mat-fab class="add-btn" (click)="createNew()">
            <mat-icon>add</mat-icon>
        </button>

        <button mat-fab class="add-btn" (click)="diagramOpen = true">
            <mat-icon>schema</mat-icon>
        </button>
        <p *ngIf="costLines.length === 0" style="margin-top: 30px;color: #aaa;" class="info-2">
            Add Line Items To The Job
        </p>
    </li>
</ul>

<div class="side-bar" *ngIf="commonOOPOpen">
    <button mat-button (click)="commonOOPOpen = false;sideBarOpenChange.emit(commonOOPOpen);" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>

    <h2 bottommargin30 class="sub-heading">Common Out Of Pocket Items</h2>
    <div *ngFor="let oop of filteredOutOfPocketItems">
        <button mat-button (click)="addCommonOutOfPocket(oop)">
            <mat-icon>add</mat-icon>
            {{oop.name}}</button>
    </div>

</div>




<app-work-order-item-type-diagram-viewer (closeClicked)="closeDiagramViewer()" *ngIf="diagramOpen"
    [workOrderItemTypeId]="workOrderItemTypeId"></app-work-order-item-type-diagram-viewer>

<div class="side-bar" *ngIf="sideBarOpen && selectedCostLine">
    <button mat-button (click)="sideBarOpen = false;sideBarOpenChange.emit(sideBarOpen);" class="close-btn">
        <mat-icon>close</mat-icon>
    </button>
    <div style="text-align: center;margin-bottom: 30px;margin-top: -40px">
        @if(selectedCostLine.isQuestion) {
        <mat-icon class="covered-icon">help</mat-icon>
        <p class="info-2">Unsure</p>
        } @else if(getIsLineCovered(selectedCostLine)) {
        <mat-icon class="covered-icon" style="color: green;">done</mat-icon>

        <p class="info-2">Item Covered</p>
        } @else {
        <mat-icon class="covered-icon" style="color: orange;">do_not_disturb_on</mat-icon>
        <p class="info-2">Out Of Pocket</p>
        }
    </div>

    <div *ngIf="!selectedCostLine.defaultItem" bottommargin20>
        <app-authorization-repair-item-selector #repairItemSelectorComponent [(gettingAttributes)]="gettingAttributes"
            (repairItemSelected)="repairItemSelected($event)" (attributesComplete)="attributesComplete($event)"
            (repairItemNameChange)="repairItemNameChange($event)" [workOrderItemTypeId]="workOrderItemTypeId"
            [repairItems]="items"></app-authorization-repair-item-selector>
    </div>

    <div *ngIf="selectedCostLine.defaultItem" style="margin-bottom: 30px;" (click)="unDefault()">
        <h2>{{selectedCostLine.description}}</h2>
    </div>

    <mat-form-field appearance="outline"
        *ngIf="selectedCostLine.allowQuantityEntry || selectedCostLine.description === 'Labor'"
        style="max-width: 130px;">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" [(ngModel)]="selectedCostLine.qty">
    </mat-form-field>

    <div aflex>
        <div nogrow>
            <mat-form-field appearance="outline" style="max-width: 160px;"
                *ngIf="!selectedCostLine.companyProvidesPart">
                <mat-label>Unit Price</mat-label>
                <input matInput type="number" [disabled]="selectedCostLine.companyProvidesPart"
                    [(ngModel)]="selectedCostLine.amount">
            </mat-form-field>
            <div *ngIf="showPriceIndicator && !selectedCostLine.companyProvidesPart" class="price-indicator-band">

                <!-- <span class="arrow" [style.left]="barPctDisplay">
                    <span [style.border-bottom-color]="arrowColorCss"></span>
                </span> -->

                <svg id="Layer_1" class="arrow-2" [style.left]="barPctDisplay" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 28.71 20.8">
                    <defs>
                        <style>
                            .cls-1 {
                                fill: #b3b3b3;
                            }

                            .cls-2 {
                                fill: lime;
                            }
                        </style>
                    </defs>
                    <path class="cls-2" [style.fill]="arrowColorCss"
                        d="M1.71,20.3c-.46,0-.87-.25-1.08-.66-.21-.41-.17-.89.1-1.26L13.37,1c.23-.32.59-.5.98-.5s.75.18.98.5l12.64,17.38c.27.38.31.85.1,1.26-.21.41-.61.66-1.08.66H1.71Z" />
                    <path class="cls-1"
                        d="M14.35,1c.23,0,.44.11.58.29l12.64,17.38c.23.32.12.62.06.74s-.24.39-.63.39H1.71c-.39,0-.57-.27-.63-.39s-.17-.42.06-.74L13.78,1.29c.14-.19.34-.29.58-.29M14.35,0c-.52,0-1.04.23-1.38.7L.33,18.08c-.82,1.13-.01,2.72,1.38,2.72h25.28c1.4,0,2.21-1.59,1.38-2.72L15.74.7c-.34-.47-.86-.7-1.38-.7h0Z" />
                </svg>
            </div>
        </div>
        <div>
            <div style="margin-top: -20px;margin-left: 20px;">
                <app-display-total [hideTriangle]="true" [smallMode]="true"
                    *ngIf="!selectedCostLine.companyProvidesPart" [total]="selectedCostLine.ext"
                    label="Subtotal"></app-display-total>
            </div>
        </div>
    </div>

    <div>
        <mat-slide-toggle [disabled]="!selectedCostLine.companyProvidedAvailable"
            [(ngModel)]="selectedCostLine.companyProvidesPart">Elevate Provided</mat-slide-toggle>
    </div>

    <div style="margin-top: 20px;">
        <mat-slide-toggle [(ngModel)]="selectedCostLine.forIncompatibility">For Incompatibilty</mat-slide-toggle>
    </div>

    <div style="margin-top: 20px;">
        <mat-slide-toggle [(ngModel)]="selectedCostLine.disputeCoverage">Don't Agree With Coverage</mat-slide-toggle>

        <div *ngIf="selectedCostLine.disputeCoverage">
            <mat-form-field appearance="outline" style="display: block; margin-top: 20px;" class="no-fixed">
                <mat-label>Reason</mat-label>
                <textarea matInput [(ngModel)]="selectedCostLine.disputeReason" rows="3"></textarea>
                <button mat-button [class.recording]="recognition" mat-suffix (click)="recognize()">
                    <mat-icon>mic</mat-icon>
                </button>
            </mat-form-field>
        </div>
    </div>

    <div class="center" topmargin40>
        <button mat-button mat-raised-button class="large" (click)="addUpdateItem()" color="primary"
            [disabled]="!canSaveItem">
            <div>
                <mat-icon>add</mat-icon>
                Save
            </div>
        </button>
    </div>

    <div style="margin-top: 30px;">
        <div class="call-out" *ngIf="selectedCostLine.authorizationRepairItemSelector?.limitPerUnit">

            There is a limit of {{selectedCostLine.authorizationRepairItemSelector?.limitPerUnit | currency}} per unit.
        </div>

        <div class="call-out" *ngIf="selectedCostLine.authorizationRepairItemSelector?.limitPerClaim">
            There is an overall limit of {{selectedCostLine.authorizationRepairItemSelector?.limitPerClaim | currency}}.
        </div>

    </div>
    <!-- <pre>{{selectedCostLine | json}}</pre> -->
</div>