<div class="hotspot-container" *ngIf="selectedDiagram">
    <button mat-icon-button class="close" (click)="close()">
        <mat-icon>close</mat-icon>
    </button>
    <div style="position: relative;">
        <img [src]="selectedDiagram.url" (load)="imageLoaded()" [id]="imageId">

        <svg version="1.2" baseProfile="tiny" [id]="svgId">

            <ng-container *ngFor="let hotspot of hotspots">
                @if(hotspot.circle) {
                <circle (click)="selectHotspot(hotspot)" style="cursor: pointer;" stroke="red" stroke-width=".5"
                    [attr.fill]="getFill(hotspot)" [attr.cx]="hotspot.circle.x" [attr.cy]="hotspot.circle.y"
                    [attr.r]="hotspot.circle.radius">
                </circle>
                }
                @if(hotspot.polygon) {
                <polygon (click)="selectHotspot(hotspot)" style="cursor: pointer;" stroke="red" stroke-width=".5"
                    [attr.fill]="getFill(hotspot)" [attr.points]="getPolygonPoints(hotspot.polygon)">
                </polygon>
                }
            </ng-container>
        </svg>
    </div>
</div>