import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { AuthorizationsApiService } from '@cogent/client/shared/services/api/authorizations-api.service';
import { Hotspot, Polygon } from '@cogent/shared/models/authorizations/work-order-item-type-diagrams.models';
import { WorkOrderItemTypeDiagram } from '@upkeeplabs/models/cogent';

@Component({
    selector: 'app-work-order-item-type-diagram-viewer',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule],
    templateUrl: './work-order-item-type-diagram-viewer.component.html',
    styleUrl: './work-order-item-type-diagram-viewer.component.scss'
})
export class WorkOrderItemTypeDiagramViewerComponent implements OnChanges {

    @Input() workOrderItemTypeId: string;
    diagrams: WorkOrderItemTypeDiagram[];
    selectedDiagram: WorkOrderItemTypeDiagram;
    hotspots: Hotspot[];
    svgId = UtilitiesService.newid();
    imageId = UtilitiesService.newid();
    @Output() closeClicked: EventEmitter<boolean> = new EventEmitter();


    constructor(private authApi: AuthorizationsApiService) { }


    close() {
        this.closeClicked.emit(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.workOrderItemTypeId.currentValue) {
            this.load();
        }
    }

    load() {
        this.authApi.getWorkOrderItemTypeDiagrams(this.workOrderItemTypeId).then(diagrams => {
            console.log(diagrams);
            this.diagrams = diagrams;
            for (const diagram of diagrams) {
                diagram.url = `${ApiService.endPointNode}authorization/work-order-item-type-diagram/${diagram.id}`;
            }
            this.selectDiagram(diagrams[0]);
        });
    }


    selectDiagram(diagram: WorkOrderItemTypeDiagram) {
        this.selectedDiagram = diagram;
        this.hotspots = JSON.parse(diagram.config);
    }

    imageLoaded() {
        const img: HTMLImageElement = document.getElementById(this.imageId) as HTMLImageElement;

        const svg = document.getElementById(this.svgId);
        svg.style.height = `${img.clientHeight}px`;
        svg.style.width = `${img.clientWidth}px`;
    }

    selectHotspot(hotspot: Hotspot) {
        // TODO: do something with it
        console.log(hotspot);
    }

    getPolygonPoints(polygon: Polygon) {
        let pointsToString = '';
        for (const point of polygon.points) {
            pointsToString += `${point.x} ${point.y} `;
        }
        pointsToString = pointsToString.trim();
        return pointsToString;
    }

    getFill(hotSpot: Hotspot) {
        return 'rgba(0,0,0,.3)';
    }
}
